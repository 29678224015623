import { func } from "prop-types"
import React, { useState } from "react"

interface Item {
  id: string
  count: number
}

interface State {
  open: boolean
  cart: Item[]
  toggleCart: () => void
  addToCart: (itemId: string) => void
  updateQty: (itemId: string, count: number) => void
  removeFromCart: (itemId: string, count: number) => void
}

export const Context: React.Context<State> = React.createContext({
  cart: [],
} as unknown as State)

const Provider = props => {
  const [open, setOpen] = useState(false)
  const [cart, setCart]: [
    cart: State["cart"],
    setCart: React.Dispatch<React.SetStateAction<State["cart"]>>
  ] = useState([] as State["cart"])

  function toggleCart() {
    setOpen(!open)
  }

  function addToCart(itemId: string) {
    const itemInCart: Item | undefined = cart.find(
      (item: Item) => item.id === itemId
    )

    setOpen(true)

    if (!!itemInCart) {
      updateQty(itemId, itemInCart.count + 1)
    } else {
      setCart([...cart, { id: itemId, count: 1 }])
    }
  }

  function updateQty(itemId: string, count: number) {
    const itemInCartIndex = cart.findIndex((item: Item) => item.id === itemId)

    if (itemInCartIndex === -1) {
      return
    }

    if (count === 0) {
      removeFromCart(itemId)
      return
    }

    const newCart: Item[] = Object.assign([], cart)

    newCart[itemInCartIndex] = { ...newCart[itemInCartIndex], count }

    setCart(newCart)
  }

  function removeFromCart(itemId: string) {
    const itemInCartIndex = cart.findIndex((item: Item) => item.id === itemId)

    if (itemInCartIndex === -1) {
      return
    }

    const newCart: Item[] = Object.assign([], cart)

    newCart.splice(itemInCartIndex)

    setCart(newCart)
  }

  return (
    <Context.Provider
      value={{
        open,
        toggleCart,
        cart,
        addToCart,
        updateQty,
        removeFromCart,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
